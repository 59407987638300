<template>
  <div class="container-fluid">
    <Header />
    <div
      class="mx-auto mt-1 content-outer-container"
      :class="{ 'content-dashboard': $store.state.isDashboard }"
    >
      <div class="mx-auto content-inner-container">
        <div class="wrap-connectLine">
          <!-- AlertSuccessLine -->
          <b-alert
            variant="success"
            dismissible
            fade
            :show="showAlertSuccessLine"
            @dismissed="showAlertSuccessLine = false"
          >
            {{ lbl['line-channel-connect-success-message'] }}
          </b-alert>

          <!-- AlertErrorLine -->
          <b-alert
            variant="danger"
            dismissible
            fade
            :show="showAlertErrorLine"
            @dismissed="showAlertErrorLine = false"
          >
            {{ lbl['line-channel-connect-error-message'] }}
          </b-alert>

          <!-- AlertSuccess -->
          <b-alert
            variant="success"
            dismissible
            fade
            :show="showAlertSuccess"
            @dismissed="showAlertSuccess = false"
          >
            {{
              lbl['line-channel-connect-regenerate-liff-url-success-message']
            }}
          </b-alert>

          <!-- AlertError -->
          <b-alert
            variant="danger"
            dismissible
            fade
            :show="showAlertError"
            @dismissed="showAlertError = false"
          >
            {{ lbl['Line Liff has already generated.'] }}
          </b-alert>

          <div class="row">
            <div class="col-12 page-header">
              <img
                :src="require('@/assets/images/line-channel-logo-header.png')"
                alt=""
              />
              {{ lbl['line-channel-header'] }}
            </div>
          </div>
          <!-- <div class="text-center my-3" ng-controller="ImportUserCtrl">
            <div class="btn-group" role="group" aria-label="Button group">
              <a-button-group>
                <a-button type="primary">
                  {{ lbl['line-channel-tab-connect'] }}
                </a-button>
                <a-button @click="goto('RichMenu')">
                  {{ lbl['line-channel-tab-setup-richmenu'] }}
                </a-button>
              </a-button-group>
            </div>
          </div> -->
          <div class="row mt-2 mb-3">
            <div class="col-12 text-right">
              <a-button
                shape="round"
                :size="size"
                @click="handleGenerateLiffUrl"
                >{{ lbl['line-channel-connect-regenerate-liff-url'] }}</a-button
              >
            </div>
          </div>
          <a-form>
            <div class="row field-row">
              <div
                class="
                  col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12
                  field-column field-label
                  col-form-label
                  field-required
                "
              >
                {{ lbl['line-channel-connect-line-message-channel-id'] }}
              </div>
              <div
                class="col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12 field-column"
              >
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <input
                      v-model="line_message_channel_id"
                      type="text"
                      class="form-control"
                      @change="changeInput"
                      :placeholder="
                        lbl[
                          'line-channel-connect-line-message-channel-id-placeholder'
                        ]
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row field-row">
              <div
                class="
                  col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12
                  field-column field-label
                  col-form-label
                  field-required
                "
              >
                {{ lbl['line-channel-connect-line-message-channel-secret'] }}
              </div>
              <div
                class="col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12 field-column"
              >
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <input
                      v-model="line_message_channel_secret"
                      type="text"
                      class="form-control"
                      @change="changeInput"
                      :placeholder="
                        lbl[
                          'line-channel-connect-line-message-channel-secret-placeholder'
                        ]
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row field-row">
              <div
                class="
                  col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12
                  field-column field-label
                  col-form-label
                  field-required
                "
              >
                {{ lbl['line-channel-connect-line-login-channel-id'] }}
              </div>
              <div
                class="col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12 field-column"
              >
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <input
                      v-model="line_login_channel_id"
                      type="text"
                      class="form-control"
                      @change="changeInput"
                      :placeholder="
                        lbl[
                          'line-channel-connect-line-login-channel-id-placeholder'
                        ]
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row field-row">
              <div
                class="
                  col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12
                  field-column field-label
                  col-form-label
                  field-required
                "
                style="border-bottom-width: 1px"
              >
                {{ lbl['line-channel-connect-line-login-channel-secret'] }}
              </div>
              <div
                class="col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12 field-column"
                style="border-bottom-width: 1px"
              >
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <input
                      v-model="line_login_channel_secret"
                      type="text"
                      class="form-control"
                      @change="changeInput"
                      :placeholder="
                        lbl[
                          'line-channel-connect-line-login-channel-secret-placeholder'
                        ]
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row field-row mt-3">
              <div class="col-12">
                <div class="text-center">
                  <p class="text-muted small mt-2">
                    {{ lbl['line-channel-connect-webhook-detail'] }}
                  </p>
                  <a-button
                    type="primary"
                    @click="handleSubmit"
                    :disabled="disabledsubmit"
                  >
                    {{ lbl['line-channel-connect-connect-button'] }}
                  </a-button>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-1"></div>
              <div class="col-md-10">
                <div class="input-group text-center">
                  <input
                    :placeholder="
                      lbl['line-channel-connect-webhook-placeholder']
                    "
                    class="form-control text-center"
                    id="WebHookUrl"
                    name="WebHookUrl"
                    readonly="readonly"
                    type="text"
                    :value="urlWebhook"
                    aria-invalid="false"
                  />
                  <div class="input-group-append">
                    <button
                      class="input-group-text copy-web-hook-url"
                      data-toggle="tooltip"
                      title=""
                      data-clipboard-target="#WebHookUrl"
                      @click="copyClipboard"
                      @mouseover="showTooltip"
                    >
                      <a-tooltip
                        slot="suffix"
                        id="tooltipClipboard"
                        :title="titleTooltip"
                      >
                        {{ lbl['line-channel-connect-copy-button'] }}
                      </a-tooltip>
                    </button>
                  </div>
                </div>
                <p class="text-muted text-center small mt-2">
                  {{ lbl['line-channel-connect-webhook-detail'] }}
                </p>
              </div>
              <div class="col-md-1"></div>
            </div>
          </a-form>
          <b-modal
            ref="my-modal-confirm"
            modal-class="modal-Confirm"
            :modalClass="[currentLocale]"
            centered
            hide-header
            hide-footer
            no-close-on-backdrop
            no-close-on-esc
          >
            <div class="modal-base">
              <div class="row mt-3">
                <div class="col-xl-11 col-lg-11 col-md-11 col-sm-10 col-10">
                  <div class="modal-base-head">
                    <img src="@/assets/images/Clipboard-check.svg" alt="" />
                    {{
                      lbl['line-channel-connect-regenerate-liff-confirm-title']
                    }}
                  </div>
                  <div class="modal-base-body mt-4">
                    {{ lbl['line-channel-connect-line-confirm-message'] }}
                  </div>
                  <div class="modal-base-body mt-2 text-danger">
                    *{{
                      lbl[
                        'line-channel-connect-regenerate-liff-confirm-message2'
                      ]
                    }}
                  </div>
                </div>
              </div>
              <div class="modal-base-footer mt-4">
                <button
                  class="btn btn-warning ml-2"
                  @click="callApiConnectLine"
                >
                  {{
                    lbl['line-channel-connect-regenerate-liff-confirm-title']
                  }}
                </button>
                <button class="btn btn-warning ml-2 cancel" @click="hideModal">
                  {{ lbl['line-channel-connect-regenerate-liff-cancel-title'] }}
                </button>
              </div>
            </div>
          </b-modal>
          <b-modal
            ref="my-modal-confirm-generate-liff"
            modal-class="modal-Confirm"
            :modalClass="[currentLocale]"
            centered
            hide-header
            hide-footer
            no-close-on-backdrop
            no-close-on-esc
          >
            <div class="modal-base">
              <div class="row mt-3">
                <div class="col-xl-11 col-lg-11 col-md-11 col-sm-10 col-10">
                  <div class="modal-base-head">
                    <span class="icoTitle"
                      ><img src="@/assets/images/Clipboard-check.svg" alt=""
                    /></span>
                    {{
                      lbl['line-channel-connect-regenerate-liff-confirm-title']
                    }}
                  </div>
                  <div class="modal-base-body mt-4">
                    {{
                      lbl[
                        'line-channel-connect-regenerate-liff-confirm-message'
                      ]
                    }}
                  </div>
                  <div class="modal-base-body mt-2 text-danger">
                    *{{
                      lbl[
                        'line-channel-connect-regenerate-liff-confirm-message2'
                      ]
                    }}
                  </div>
                </div>
              </div>
              <div class="modal-base-footer mt-4">
                <button
                  class="btn btn-warning ml-2"
                  @click="callApiGenerateLiffUrl"
                >
                  {{
                    lbl['line-channel-connect-regenerate-liff-confirm-title']
                  }}
                </button>
                <button
                  class="btn btn-warning ml-2 cancel"
                  @click="hideModalGenerateLiffUrl"
                >
                  {{ lbl['line-channel-connect-regenerate-liff-cancel-title'] }}
                </button>
              </div>
            </div>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import BzbsSettingsLine from '@/core/Settings/Line/BzbsSettingsLine'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import Header from '@/components/Layout/Header'
import Locale from '@/helper/locale.js'

export default {
  name: 'ConnectLine',
  mixins: [Mixin],
  components: {
    Header,
  },
  data() {
    return {
      currentLocale: Locale.getLocaleShort(),
      host: window.location.origin,
      line_message_channel_id: '',
      line_message_channel_secret: '',
      line_login_channel_id: '',
      line_login_channel_secret: '',
      titleTooltip: '',
      disabledsubmit: true,
      urlWebhook: '',
      showAlertSuccess: false,
      showAlertError: false,
      showAlertSuccessLine: false,
      showAlertErrorLine: false,
    }
  },
  created() {
    this.handleFooter(true)
    this.init()
    this.getCRMPlusProfile().then(() => {
      this.getConnectLine()
    })
  },
  methods: {
    getCRMPlusProfile() {
      this.handleLoading(true)
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            this.handleLoading(false)
            console.log('getCRMPlusProfile : ', res.data)

            if (res.data.StoreType == null || res.data.StoreType == '') {
              window.location.href = this.host + '/PlanReward/SetupPlan'
            }

            if (res.data.LineMessageChannelId != null) {
              this.line_message_channel_id = res.data.LineMessageChannelId
            }
            if (res.data.LineMessageSecretKey != null) {
              this.line_message_channel_secret = res.data.LineMessageSecretKey
            }
            if (res.data.LineLoginChannelId != null) {
              this.line_login_channel_id = res.data.LineLoginChannelId
            }
            if (res.data.LineLoginSecretKey != null) {
              this.line_login_channel_secret = res.data.LineLoginSecretKey
            }
            setTimeout(() => this.changeInput(), 300)

            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('apiGetAppCategoryList error', error)
            resolve(error)
          })
      })
    },
    init() {
      this.titleTooltip =
        this.lbl['line-channel-connect-webhook-tooltip-before-copy']
    },
    copyClipboard() {
      var copyText = document.getElementById('WebHookUrl')
      navigator.clipboard.writeText(copyText.value)
      this.titleTooltip =
        this.lbl['line-channel-connect-webhook-tooltip-after-copy']
    },
    showTooltip() {
      this.titleTooltip =
        this.lbl['line-channel-connect-webhook-tooltip-before-copy']
    },
    changeInput() {
      if (
        this.line_message_channel_id != '' &&
        this.line_message_channel_id != null &&
        this.line_message_channel_secret != '' &&
        this.line_message_channel_secret != null &&
        this.line_login_channel_id != '' &&
        this.line_login_channel_id != null &&
        this.line_login_channel_secret != '' &&
        this.line_login_channel_secret != null
      ) {
        this.disabledsubmit = false
      } else {
        this.disabledsubmit = true
      }
    },
    handleSubmit() {
      this.$refs['my-modal-confirm'].show()
    },
    hideModal() {
      this.$refs['my-modal-confirm'].hide()
    },
    getConnectLine() {
      this.handleLoading(true)

      this.showAlertSuccessLine = false
      this.showAlertErrorLine = false
      this.showAlertSuccess = false
      this.showAlertError = false
      let params = {
        lineMessageChannelId: this.line_message_channel_id,
        lineMessageSecretKey: this.line_message_channel_secret,
        lineLoginChannelId: this.line_login_channel_id,
        lineLoginSecretKey: this.line_login_channel_secret,
      }
      return new Promise(resolve => {
        BzbsSettingsLine.getConnectLine(params)
          .then(res => {
            this.handleLoading(false)
            this.urlWebhook = res.data.webHookUrl
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('getConnectLine error', error)
            resolve(error)
          })
      })
    },
    callApiConnectLine() {
      this.handleLoading(true)
      this.showAlertSuccessLine = false
      this.showAlertErrorLine = false
      this.showAlertSuccess = false
      this.showAlertError = false
      let params = {
        lineMessageChannelId: this.line_message_channel_id,
        lineMessageSecretKey: this.line_message_channel_secret,
        lineLoginChannelId: this.line_login_channel_id,
        lineLoginSecretKey: this.line_login_channel_secret,
      }
      return new Promise(resolve => {
        BzbsSettingsLine.postConnectLine(params)
          .then(res => {
            this.handleLoading(false)
            this.$refs['my-modal-confirm'].hide()
            this.urlWebhook = res.data.webHookUrl
            if (res.status == 200) {
              this.showAlertSuccessLine = true
            }
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            this.showAlertErrorLine = true
            console.log('postConnectLine error', error)
            this.$refs['my-modal-confirm'].hide()
            resolve(error)
          })
      })
    },
    handleGenerateLiffUrl() {
      this.$refs['my-modal-confirm-generate-liff'].show()
    },
    hideModalGenerateLiffUrl() {
      this.$refs['my-modal-confirm-generate-liff'].hide()
    },
    callApiGenerateLiffUrl() {
      this.handleLoading(true)
      this.showAlertSuccessLine = false
      this.showAlertErrorLine = false
      this.showAlertSuccess = false
      this.showAlertError = false
      let params = {}
      return new Promise(resolve => {
        BzbsSettingsLine.postGenerateLiff(params)
          .then(res => {
            this.handleLoading(false)
            console.log('postGenerateLiff : ', res)
            this.$refs['my-modal-confirm-generate-liff'].hide()
            if (res.status == 200) {
              this.showAlertSuccess = true
            }
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('postGenerateLiff error', error)
            this.showAlertError = true
            this.$refs['my-modal-confirm-generate-liff'].hide()
            resolve(error)
          })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.modal-body {
  padding: 1.5rem 2rem;

  .modal-base-head {
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.15px;
    text-align: left;
    color: rgba(0, 0, 0, 0.85);
    .icoTitle {
      width: 44px;
      height: 44px;
      padding: 5px 10px 10px 10px;
      border-radius: 4px;
      background-color: rgba(76, 175, 80, 0.1);
    }
  }

  .modal-base-body {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.15px;
    text-align: left;
    color: #616161;
  }

  .modal-base-footer {
    text-align: end;
  }

  .btn-warning {
    color: #ffffff;
    background-color: #ffc107;
    border-color: #ffc107;

    &:hover {
      color: #ffffff;
      background-color: #ecb100;
      border-color: #ecb100;
    }
    &.cancel {
      color: #ffc107;
      background-color: #fff;
      border-color: #ffc107;
      &:hover {
        color: #ffffff;
        background-color: #ecb100;
        border-color: #ecb100;
      }
    }
  }
}
</style>
<style lang="scss">
.wrap-connectLine {
  .ant-form {
    padding: 0 15px;
  }
}
</style>
